import React, { useRef } from "react";
import './contact.css';
import linkedInImg from '../../assets/linkedInImg.png';
import viberImg from '../../assets/viberImg.png';
import githubImg from '../../assets/githubImg.png';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_1ephpds', 'template_i2agtgd', form.current, 'INUOYJVFs_c2MurKg')
          .then((result) => {
              console.log(result.text);
              e.target.reset();
              alert('Email sent!');
          }, (error) => {
              console.log(error.text);
          });
      };


      const linkedInLink='https://linkedin.com/in/franchizsalvs';
      const githubLink='https://github.com/FranChiz28';
      

    return(
        <section id="contactContainer">
            <div id="contact">
                <h1 className="contactTitle">Contact Me</h1>
                <span className="contactDescription">Please fill out the form below to discuss any work opportunities.</span>
                <form className="contactForm" ref={form} onSubmit={sendEmail}>
                    <input type="text" className="name" placeholder="Your Name" name="from_name" />
                    <input type="email" className="email" placeholder="Your E-mail" name="from_email" />
                    <textarea name="message" placeholder="Your Message" cols="30" rows="10" className="message"></textarea>
                    <button type="submit" className="submitBtn" value="Send">Submit</button>
                    <div className="links">
                        <a href={linkedInLink} target="_blank;"><img src={linkedInImg} alt="linkedIn-img" className="linkImg" /></a>
                        <img src={viberImg} alt="viber-img" className="linkImg" onClick={()=>{alert("Viber Number: 0976-047-1202")}}/>
                        <a href={githubLink} target="_blank;"><img src={githubImg} alt="github-img" className="linkImg" /></a>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contact;