import React, { useState } from 'react';
import './navbar.css'
import logo from '../../assets/navbarLogo.png';
import contactImg from '../../assets/contactImg.png'
import menuImg from '../../assets/menuImg.png';
import { Link } from 'react-scroll';


const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <nav className="Navbar">
            <img src={logo} alt="logo" className='logo' />
            <div className="desktopMenu">
            <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">Home</Link>
            <Link activeClass='active' to='skills' spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">About</Link>
            <Link activeClass='active' to='works' spy={true} smooth={true} offset={-90} duration={500} className="desktopMenuListItem">Projects</Link>
            </div>
            <button className="desktopMenuBtn" onClick={() => {
                document.getElementById('contact').scrollIntoView({behavior:'smooth',block:'start',inline:'nearest', offset:-100})
            }}>
                <img src={contactImg} alt="contactImg" className="desktopMenuImg" />Contact Me
            </button>

            <img src={menuImg} alt="Menu" className='mobMenu' onClick={()=>setShowMenu(!showMenu)} />
            <div className="navMenu" style={{display: showMenu? 'flex':'none'}}>
            <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-100} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Home</Link>
            <Link activeClass='active' to='skills' spy={true} smooth={true} offset={-100} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>About</Link>
            <Link activeClass='active' to='works' spy={true} smooth={true} offset={-90} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Projects</Link>
            <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-90} duration={500} className="listItem" onClick={()=>setShowMenu(false)}>Contacts</Link>
            </div>
        </nav>
    )
}

export default Navbar;