import React from 'react';
import './intro.css';
import resumeImg from '../../assets/resumeImg.png';


const Intro = () => {
    return (
        <section id="intro">
            <div className="introContent">
                <span id="hello">Hello,</span>
                <span id="introText">I'm <span id="introName">Chiz</span> <br /> Website Developer</span>
                <p className='introPara'>I am a passionate and aspiring web developer armed with skills <br /> to create user friendly and mobile responsive websites.</p>
                <a href='https://drive.google.com/file/d/1QSJUVf9hpiQD-4ziB5gy2xqBts7JPMO5/view?usp=sharing' target='_blank;'><button className="resumeBtn"><img src={resumeImg} alt="resumeImg" id="resumeImg" />Download Resume</button></a>
            </div>

        </section>
    )
}

export default Intro;