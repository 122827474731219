import React from "react";
import './skills.css';
import webDevImg from '../../assets/webDevImg.png';
import dataAnalysisImg from '../../assets/dataAnalysisImg.png';
import dataEntryImg from '../../assets/dataEntryImg.jpg';


const Skills = () =>{
    return (
        <section id="skills">
            <span className="SkillTitle">What I do</span> <br />
            <span className="skillDescription">I am a skilled and passionate developer with great enthusiasm on learning new skills to empower my knowledge in website development. I am proficient in HTML, CSS, and JavaScript, with skills on Statistical Analysis, Counseling, Bookkeeping, and Data Management, from my former experience on Customer Service, Psychology, and Office Administration.</span>
            <div className="skillBars">
                <div className="skillBar">
                    <img src={webDevImg} alt="web-development" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>Website Development</h2>
                        <p>The process of designing, building, and maintaining websites, involving both front-end interfaces for user interaction and back-end functionality for data processing and server management.</p>
                    </div>
                </div>
            </div>
            <div className="skillBars">
                <div className="skillBar">
                    <img src={dataAnalysisImg} alt="data-analysis" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>Data Analysis</h2>
                        <p>Involves exploring, cleaning, and interpreting raw data to uncover meaningful insights, patterns, and trends that drive informed decision-making.</p>
                    </div>
                </div>
            </div>
            <div className="skillBars">
                <div className="skillBar">
                    <img src={dataEntryImg} alt="data-entry" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>Data Entry</h2>
                        <p>The systematic and accurate input of information into databases, spreadsheets, or other digital platforms, ensuring the integrity and accessibility of data records.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills;