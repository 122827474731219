import React from "react";
import './projects.css';
import portfolioImg from '../../assets/portfolioImg.jpg';
import cinemateImg from '../../assets/cinemateImg.jpg';
import pollcraftersImg from '../../assets/pollcraftersImg.jpg'

const Works = () => {
    return (
        <section id="works">
            <h2 className="worksTitle">What I did</h2>
            <span className="worksHeader">Having started with zero knowledge on website development as a career shifter, I take pride to the skills I gained on a short period of time and was able to create the following projects:</span>
            <div className="worksContainer">
            <div className="projectContainer">
                <img src={portfolioImg} alt="my-portfolio" className="worksImage" />
                <div className="worksDescription">
                    <h3 className="projectTitle">Portfolio v.2</h3>
                    <p className="projectDescription">An updated version of my portfolio using ReactJS, HTML, CSS, and JavaScript.</p>
                </div>
            </div>
            <div className="projectContainer">
                <img src={cinemateImg} alt="cinemate" className="worksImage" />
                <div className="worksDescription">
                    <h3 className="projectsTitle">Cinemate</h3>
                    <p className="projectsDescription">An application used to generate list of upcoming movies and user experience of adding it to the library and seat reservation function, developed with the use of NodeJS, AXIOS, and RESTful APIs. </p>
                    <a href="https://wd77p-cinemate.onrender.com/" target="_blank;"><button className="goToBtn">Go to Project</button></a>
                </div>
            </div>
            <div className="projectContainer">
                <img src={pollcraftersImg} alt="pollcrafters" className="worksImage" />
                <div className="worksDescription">
                    <h3 className="projectsTitle">Poll Crafters Survey</h3>
                    <p className="projectsDescription">An application used to create a publicly shared survey to gather data and store it in a database. Used ReactJS, HTML, CSS - Tailwind, JavaScript for the front-end, Laravel for the back-end, and MySQL for the database.</p>
                    <a href="https://github.com/FranChiz28/poll-crafters-survey/tree/test-francis" target="_blank;"><button className="goToBtn">Go to Project</button></a>
                </div>
                </div>
            </div>
        </section>
    )
}

export default Works;